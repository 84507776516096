import React, {useState} from 'react';
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import {CardContent, Stack, TextField} from "@mui/material";

function EditFlashCard({current, setCurrent, onChange}) {

    function handleOnChange(newValue) {
        return setCurrent(newValue);
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={6}>
                <Card sx={{minWidth: 300}}>
                    <CardContent>
                        <TextField
                            variant={"outlined"}
                            label={"Question"}
                            sx={{width: '100%'}}
                            multiline
                            rows={7}
                            value={current.question || ''}
                            onBlur={(e) => onChange(current)}
                            onChange={(e) => handleOnChange(Object.assign({}, current, {question: e.target.value}))}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6}>
                <Card sx={{minWidth: 300}}>
                    <CardContent>
                        <Stack direction="column" spacing={1.6}>
                            <TextField
                                variant={"outlined"}
                                label={"Answer"}
                                sx={{width: '100%'}}
                                multiline
                                rows={4}
                                value={current.answer || ''}
                                onBlur={(e) => onChange(current)}
                                onChange={(e) => handleOnChange(Object.assign({}, current, {answer: e.target.value}))}
                            />
                            <TextField
                                variant={"outlined"}
                                label={"Description"}
                                sx={{width: '100%'}}
                                value={current.description || ''}
                                onBlur={(e) => onChange(current )}
                                onChange={(e) => handleOnChange(Object.assign({}, current, {description: e.target.value}))}
                            />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default EditFlashCard;
