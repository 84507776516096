import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserStateContext} from "../context/UserStateContext";
import {ErrorStateContext} from "../context/ErrorContext";

function useLogin() {
    const navigate = useNavigate();
    const {dispatch: userDispatch} = useContext(UserStateContext);
    const {dispatch: errorDispatch} = useContext(ErrorStateContext);

    const [user, setUser] = useState();

    useEffect(() => {
        fetch('/api/user/info')
            .then(response => {
                if (response.status === 401) {
                    console.log('User is not authenticated');
                    window.location.href = '/login';
                    return null;
                }
                if (!response.ok) {
                    throw new Error('Failed to fetch user information');
                }
                return response.json();
            })
            .then(data => {
                if (!data) {
                    return;
                }
                setUser(data);
                userDispatch({type: 'SET_USER', payload: data});
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
                errorDispatch({type: 'SET_ERROR', payload: error});
                navigate('/error');
            });

    }, [navigate, userDispatch, errorDispatch]);

    const logout = () => {
        window.location.href = '/logout';
    };

    return {user: user, logout};
}

export default useLogin;
