import React, { createContext, useReducer } from 'react';

const initialUserState = {
    user: null,
};

const UserStateContext = createContext(initialUserState);

const globalUserStateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
};

const GlobalUserStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalUserStateReducer, initialUserState);

    return (
        <UserStateContext.Provider value={{ state, dispatch }}>
            {children}
        </UserStateContext.Provider>
    );
};

export { UserStateContext, GlobalUserStateProvider };