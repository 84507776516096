import React, {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorDescription: null
        };
    }

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', JSON.stringify(error));
        return {hasError: true, error: error};
    }

    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            console.log('render ERROR', this.state.error.message);
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <p>{this.state.error.message}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;