import {Box, Chip, IconButton, InputLabel, MenuItem, Stack,FormControl, Select} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ChecklistIcon from '@mui/icons-material/Checklist';
import RestoreIcon from '@mui/icons-material/Restore';

import React from "react";
import {useNavigate} from "react-router-dom";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";


function EditFlashCardsControls(
    {
        onAdd,
        onDelete,
        onMoveBackward,
        onMoveForward,
        onResetCards,
        idx,
        dataLength,
        flashCardSetId,
        summary,
        filter,
        setFilter
    }
) {
    const navigate = useNavigate();

    function handleFilterChange(event) {
        setFilter(event.target.value);
    }

    return (
        <Stack direction="row" spacing={1} marginBottom="1em">
            <IconButton onClick={onAdd}>
                <AddCircleOutlineIcon/>
            </IconButton>
            <IconButton onClick={onDelete}>
                <RemoveCircleOutlineIcon/>
            </IconButton>
            <IconButton onClick={() => navigate(`/flash-card-sets/${flashCardSetId}/wizard`)}>
                <PlayForWorkIcon/>
            </IconButton>
            <IconButton disabled={summary.undefined === 0}
                        onClick={() => navigate(`/flash-card-sets/${flashCardSetId}/check`)}>
                <ChecklistIcon/>
            </IconButton>
            <IconButton disabled={summary.count === 0} onClick={() => onResetCards()}>
                <RestoreIcon/>
            </IconButton>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Filter</InputLabel>
                <Select
                    id="filter-select"
                    labelId="demo-select-small-label"
                    value={filter}
                    label="Filter"
                    onChange={handleFilterChange}
                >
                    <MenuItem value={""}><em>All</em></MenuItem>
                    <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                    <MenuItem value={"LEARNED"}>Learned</MenuItem>
                </Select>
            </FormControl>
            <IconButton onClick={onMoveBackward}>
                <ArrowCircleUpIcon sx={{transform: 'rotate(-90deg)'}}/>
            </IconButton>
            <Box sx={{paddingTop: 0.5}}>
                <Chip label={`${idx + 1} / ${dataLength}`} variant='outlined'/>
            </Box>
            <IconButton onClick={onMoveForward}>
                <ArrowCircleUpIcon sx={{transform: 'rotate(90deg)'}}/>
            </IconButton>
        </Stack>
    );
}

export default EditFlashCardsControls;
