import {Box, Tooltip} from "@mui/material";


function CardSetProgress({progress}) {
    if (progress.count === 0) {
        return null;
    }

    const undefinedPercentage = (progress.undefined * 100 / progress.count).toFixed(2) + "%";
    const known = (progress.known * 100 / progress.count).toFixed(2) + "%";
    const unknown = (progress.unknown * 100 / progress.count).toFixed(2) + "%";
    const learned = (progress.learned * 100 / progress.count).toFixed(2) + "%";

    return (
        <Box sx={{
            border: "solid 2px lightgray",
            borderRadius: 2,
            height: 20,
            display: "flex",
            alignItems: "row",
            overflow: "hidden",
        }}>
            <Tooltip title={`Undefined: ${progress.undefined}`}>
                <Box sx={{height: 20, width: undefinedPercentage, background: "#eeeeee"}}>
                </Box>
            </Tooltip>
            <Tooltip title={`Known: ${progress.known}`}>
                <Box sx={{height: 20, width: known, background: "#e6ee9c"}}>
                </Box>
            </Tooltip>
            <Tooltip title={`Unknown: ${progress.unknown}`}>
                <Box sx={{height: 20, width: unknown, background: "#ffcc80"}}>
                </Box>
            </Tooltip>
            <Tooltip title={`Learned: ${progress.learned}`}>
                <Box sx={{height: 20, width: learned, background: "#a5d6a7"}}>
                </Box>
            </Tooltip>

        </Box>

    );
}

export default CardSetProgress;
