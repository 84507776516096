import React, {useEffect, useState} from 'react';
import {PieChart} from "@mui/x-charts";
import {Skeleton} from "@mui/material";

function OverallProgress({count}) {
    const [progressData, setProgressData] = useState([]);
    useEffect(() => {
        fetch('/api/flash-card-sets/progress')
            .then(response => response.json())
            .then(data => {
                const total = data.known + data.unknown + data.learned + data.undefined;
                if (total > 0) {
                    setProgressData([
                        {label: 'Known', color:"#DDEE77", value: data.known},
                        {label: 'Unknown', color:"#ff8480", value: data.unknown},
                        {label: 'Learned', color:"#a5d6a7", value: data.learned},
                        {label: 'Undefined', color:"#eeeeee", value: data.undefined}
                    ]);
                }
            });
    }, [count]);


    if (progressData.length === 0 || count === 0) {
        return (
            <Skeleton variant="rectangular" height={200}/>
        );
    }
    return (
        <PieChart
            margin={{ right: 15 }}
            height={200}
            series={[
                {
                    data: progressData,
                    innerRadius: 60
                }
            ]}
            slotProps={{legend: {hidden: true}}}
        />
    );
}

export default OverallProgress;
