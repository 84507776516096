import React from 'react';
import {Container} from "@mui/material";
import Header from "./Header";
import FlashCardSetHeader from "./FlashCardSetHeader";

function AddFlashCardSet() {
    return (
        <Container maxWidth="xlg">
            <Header/>
            <FlashCardSetHeader/>
        </Container>
    );
}

export default AddFlashCardSet;
