import React, {useState} from "react";
import useLogin from "../hook/useLogin";
import {Avatar, Box, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

function UserInfo() {
    const {user, logout} = useLogin();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout()
    }
    if (!user) {
        return null;
    }
    let fullName = `${user.firstName} ${user.lastName}`;

    function getIconButton() {
        if (user) {
            return <Avatar alt={fullName} src="/api/user/avatar.jpg" onClick={handleMenu}/>
        }
        return <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
        >
            <AccountCircle/>
        </IconButton>;
    }

    return (
        <div>
            {getIconButton()}
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Box sx={{p: 2}}>
                    <Typography gutterBottom sx={{color: 'text.secondary', mb: 1.5}}>
                        {fullName}
                    </Typography>
                </Box>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
        </div>
    );
}

export default UserInfo;
