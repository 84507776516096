import React, { useContext } from 'react';
import { ErrorStateContext } from '../context/ErrorContext';

function ErrorPage() {
    const { state } = useContext(ErrorStateContext);
    const errorMessage = state.error ? state.error.message : 'An unknown error occurred';

    return (
        <div>
            <h1>Error</h1>
            <p>{errorMessage}</p>
        </div>
    );
}

export default ErrorPage;