import React, {useState} from "react";
import Header from "./Header";
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import {Container, Skeleton} from "@mui/material";
import FlashCardSetList from "./FlashCardSetList";
import OverallProgress from "./OverallProgress";


function Dashboard() {
    const [count, setCount] = useState(0);
    return (
        <Container maxWidth="xlg">
            <Header/>
            <Paper>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <Skeleton variant="rectangular" height={100}/>
                    </Grid>

                    <Grid size={9}>
                        <FlashCardSetList setCountFn={setCount}/>
                    </Grid>
                    <Grid size={3}>
                        <OverallProgress count={count}/>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Dashboard;
