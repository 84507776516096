import Header from "./Header";
import FlashCardSetHeader from "./FlashCardSetHeader";
import React from "react";

function EditFlashCardSetWizardHeader({id}) {
    return (
        <>
            <Header/>
            <FlashCardSetHeader id={id} disabled={true}/>
        </>

    );
}
export default EditFlashCardSetWizardHeader;