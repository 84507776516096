import React, {useEffect, useState} from 'react';
import {IconButton, Skeleton, Stack} from "@mui/material";
import useLogin from "../hook/useLogin";
import {DataGrid} from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {useNavigate} from "react-router-dom";
import {getCsrfToken} from "../service/CsrfService";

function FlashCardSetList({setCountFn}) {
    const {user} = useLogin();
    const [flashCardSetList, setFlashCardSetList] = useState(null)
    const [deleteIconEnabled, setDeleteIconEnabled] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const navigate = useNavigate();

    function getFlashCardSetList() {
        return fetch('/api/flash-card-sets')
            .then(response => response.json())
    }


    useEffect(() => {
        if (user) {
            getFlashCardSetList()
                .then(data => setFlashCardSetList(data));
        }
    }, [user]);

    if (!flashCardSetList) {
        return (
            <Skeleton variant="rectangular" height={300}/>
        );
    }

    const columns = [
        {
            field: 'updatedAt',
            headerName: 'Date',
            width: 130,
            valueGetter: (value) => new Date(value).toLocaleDateString(),
            renderCell: (params) => (
                <div style={{cursor: 'pointer'}}>
                    {params.value}
                </div>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 130,
            flex: 1,
            renderCell: (params) => (
                <div style={{cursor: 'pointer'}}>
                    {params.value}
                </div>
            )

        },
        {
            field: 'progress',
            headerName: 'Progress',
            sortable: false,
            width: 160,
            renderCell: (params) => {
                let percentage = 0;
                if (params.row.unknown !== 0) {
                    percentage = (params.row.learned / params.row.unknown) * 100;
                }
                return (
                    <div className='progress-cell' style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50px',
                        width: '100%',
                        cursor: 'pointer'
                    }}>
                        <div style={{width: '100%', height: 10, backgroundColor: '#f0f0f0', borderRadius: 5}}>
                            <div style={{
                                width: `${percentage}%`,
                                height: 10,
                                backgroundColor: '#a5d6a7',
                                borderRadius: 5
                            }}/>
                        </div>
                    </div>
                );
            }
        },
    ];

    function handleDelete() {
        if (selectedIds.length > 0) {
            getCsrfToken()
                .then(csrfToken =>
                    fetch('/api/flash-card-sets', {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-Token': csrfToken
                        },
                        body: JSON.stringify(selectedIds)
                    }))
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete');
                    }
                })
                .then(getFlashCardSetList)
                .then((data) => {
                    console.log("refreshed data", data);
                    setDeleteIconEnabled(false);
                    setSelectedIds([]);
                    setFlashCardSetList(data);
                    setCountFn(data.length);
                });
        }
    }

    function handelAdd() {
        navigate('/flash-card-sets/new');
    }

    function handleCellOnClick(params) {
        if (params.field === 'name' || params.field === 'updatedAt') {
            navigate(`/flash-card-sets/${params.id}`)
        }
    }

    return (
        <div>
            <Stack direction="row" spacing={1}>
                <IconButton onClick={handelAdd}>
                    <AddCircleOutlineIcon/>
                </IconButton>
                <IconButton disabled={!deleteIconEnabled} onClick={handleDelete}>
                    <RemoveCircleOutlineIcon/>
                </IconButton>
            </Stack>
            <DataGrid
                columns={columns}
                rows={flashCardSetList}
                pageSize={5}
                checkboxSelection
                onRowSelectionModelChange={(selectionModel) => {
                    setSelectedIds(selectionModel);
                    setDeleteIconEnabled(selectionModel.length > 0);
                }}
                onCellClick={handleCellOnClick}
            />
        </div>
    );
}

export default FlashCardSetList;
