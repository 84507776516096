import React, {useCallback, useMemo} from 'react';
import {useParams} from "react-router-dom";
import useCursor from "../hook/useCursor";
import CheckFlashCard from "./CheckFlashCard";
import {getCsrfToken} from "../service/CsrfService";

function CheckFlashCards() {
    const {id: flashCardSetId} = useParams()


    const fetchDataFn = useCallback(() => {
            return fetch(`/api/flash-card-sets/${flashCardSetId}/cards`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Failed to fetch cards');
                })
                .then(data => {
                    return data.filter(card => {
                        return card.known === "UNDEFINED";
                    });
                });
        },
        [flashCardSetId]);

    const initialElement = useMemo(() => {
        return {question: "", answer: "", description: ""};
    }, []);

    const onUpdateFn = useCallback((newValue) => {
        console.log('updateFlashCard', newValue);
        return getCsrfToken()
            .then((csrfToken) =>
                fetch(`/api/flash-card-sets/${flashCardSetId}/cards`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-Token': csrfToken
                        },
                        body: JSON.stringify(newValue)
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Failed to update card');
                    })
            );
    }, [flashCardSetId]);

    const {
        currentElement,
        dataLength,
        idx,
        onChange,
        insertFn,
        deleteFn,
        moveForwardFn,
        moveBackwardFn,
        summary
    } = useCursor(
        flashCardSetId,
        fetchDataFn,
        initialElement,
        onUpdateFn,
        (_) => {
        },
        (_) => {
            return true;
        }
    );
    console.log('CheckFlashCards', currentElement, dataLength, idx);

    return (
        <div>
            <CheckFlashCard flashCardSetId={flashCardSetId} current={currentElement} length={dataLength} idx={idx} onChange={onChange}
                            onInsert={insertFn} onDelete={deleteFn} onMoveForward={moveForwardFn}
                            onMoveBackward={moveBackwardFn} summary={summary}/>
        </div>
    );
}

export default CheckFlashCards;
