import React, {useEffect, useState} from "react";
import {Button, IconButton, LinearProgress, Snackbar, Stack, TextField} from "@mui/material";
import {getCsrfToken} from "../service/CsrfService";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import {useNavigate} from "react-router-dom";

function EditFlashCardSetWizardBody({id}) {
    const [text, setText] = useState("");
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const [uploadStarted, setUploadStarted] = React.useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (uploadStarted) {
            const eventSource = new EventSource(`/api/sse/flash-card-sets/${id}/upload-progress`);
            eventSource.addEventListener('message', (event) => {
                console.log('message', event);
                const newProgress = parseInt(event.data, 10);
                setProgress(newProgress);
                if (newProgress === 100) {
                    setUploadStarted(false);
                    eventSource.close();
                    navigate(`/flash-card-sets/${id}/check`);
                }
            });
            return () => {
                console.log('cleanup');
                eventSource.close();
                setUploadStarted(false);
            };
        }
    }, [id, uploadStarted]);


    const HiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    async function handleFileUpload(event) {
        const selectedFile = event.target.files[0];
        console.log(selectedFile);
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const csrfToken = await getCsrfToken();
            const response = await fetch(`/api/flash-card-sets/${id}/upload`, {
                method: 'POST',
                headers: {
                    'X-XSRF-Token': csrfToken,
                },
                body: formData,
            });

            setUploadStarted(true);

            if (response.ok) {
                setMessage('File uploaded successfully');
                setOpen(true);
            } else {
                setMessage('Failed to upload file');
                setOpen(true);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    async function pushTextToServer() {
        const csrfToken = await getCsrfToken();
        const response = await fetch(`http://localhost:8080/api/flash-card-sets/${id}/cards/text`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken
            },
            body: text
        });

        setUploadStarted(true);

        if (response.ok) {
            setMessage('Text uploaded successfully');
            setOpen(true);
        } else {
            setMessage('Failed to upload text');
            setOpen(true);
        }
    }

    const handleClose = (event, reason) => {
        console.log('handleClose', event, reason);
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Stack direction="row" spacing={1}>
                <label htmlFor="icon-button-file">
                    <HiddenInput id="icon-button-file" type="file"
                                 onChange={handleFileUpload}/>
                    <IconButton
                        component="span"
                    >
                        <CloudUploadIcon/>
                    </IconButton>
                </label>
                <IconButton onClick={pushTextToServer}>
                    <PlayForWorkIcon/>
                </IconButton>

            </Stack>
            {uploadStarted && <LinearProgress variant="determinate" value={progress} />}
            <TextField
                multiline={true}
                rows={20} sx={{width: "100%"}}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            />
        </>
    );
}

export default EditFlashCardSetWizardBody;
