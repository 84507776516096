import React from 'react';
import {Container} from "@mui/material";
import {useParams} from "react-router-dom";
import EditFlashCardSetWizardHeader from "./EditFlashCardSetWizardHeader";
import EditFlashCardSetWizardBody from "./EditFlashCardSetWizardBody";


function EditFlashCardSetWizard() {
    const {id} = useParams();
    return (
        <Container maxWidth="xlg">
            <EditFlashCardSetWizardHeader id={id}/>
            <EditFlashCardSetWizardBody id={id}/>
        </Container>
    );
}

export default EditFlashCardSetWizard;