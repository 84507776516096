import React, { createContext, useReducer } from 'react';

const initialErrorState = {
    error: null,
};

const ErrorStateContext = createContext(initialErrorState);

const globalErrorStateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

const GlobalErrorStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalErrorStateReducer, initialErrorState);

    return (
        <ErrorStateContext.Provider value={{ state, dispatch }}>
            {children}
        </ErrorStateContext.Provider>
    );
};

export { ErrorStateContext, GlobalErrorStateProvider };