import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {},
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    width: "1.3em",
                    height: "1.3em",
                },
            },
        },
    },
});
export default theme;
