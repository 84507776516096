import {useCallback, useEffect, useRef, useState} from "react";

function useCursor(flashCardSetId, fetchDataFn, initialElement, onUpdateFn, onDeleteFn, validateFn, filterFn) {
    console.log("cursor hook");

    const [summary, setSummary] = useState({unknown: 0, known: 0, learned: 0, undefined: 0});
    const [idx, setIdx] = useState(0)
    const [dataLength, setDataLength] = useState(1)
    const [currentElement, setCurrentElement] = useState(initialElement);
    const dataRef = useRef([]);

    function fetchData() {
        console.log("fetch data")
        fetchDataFn()
            .then(data => {
                if (filterFn) {
                    data = data.filter(filterFn);
                }
                if (data.length === 0) {
                    data = [initialElement];
                }
                dataRef.current = data;
                const first = data[0];
                console.log('first', first);
                setCurrentElement(first);
                setDataLength(data.length);
                setIdx(0)
                calcSummary();
            })

    }

    useEffect(() => {
        fetchData();
    }, [flashCardSetId, fetchDataFn, initialElement, filterFn]);


    const onChange = useCallback((newValue) => {
        console.log('onChange', newValue);
        if (validateFn(newValue)) {
            console.log("validation passed");
            return onUpdateFn(newValue).then((updated) => {
                setCurrentElement(updated);
                dataRef.current = dataRef.current.map((element, index) => index === idx ? updated : element);
                calcSummary();
            });
        } else {
            setCurrentElement(newValue);
            dataRef.current = dataRef.current.map((element, index) => index === idx ? newValue : element);
            calcSummary();
            return Promise.resolve(newValue);
        }
    }, [setCurrentElement, onUpdateFn, validateFn, idx]);

    const insertFn = (_) => {
        dataRef.current = [...dataRef.current, initialElement];
        const newIdx = dataRef.current.length - 1;
        setIdx(newIdx);
        setCurrentElement(dataRef.current[newIdx]);
        setDataLength(dataRef.current.length);
        calcSummary();
    };

    const deleteFn = (_) => {
        const id = dataRef.current[idx].id;
        let newIdx = idx;
        if (id) {
            onDeleteFn(id).then(() => {
                console.log('deleted');
            });
        }
        dataRef.current = dataRef.current.filter((element, index) => index !== idx);
        if (dataRef.current.length === 0) {
            newIdx = 0;
            setIdx(newIdx);
            dataRef.current = [initialElement];
            setCurrentElement(initialElement);
            return;
        }
        if (idx === dataRef.current.length) {
            newIdx = dataRef.current.length - 1;
            setIdx(newIdx);
        }
        setCurrentElement(dataRef.current[newIdx]);
        setDataLength(dataRef.current.length);
        calcSummary();
    };

    const moveForwardFn = (_) => {
        if (idx === dataRef.current.length - 1) {
            return idx;
        }
        setIdx(idx + 1);
        setCurrentElement(dataRef.current[idx + 1]);
        return idx + 1;
    };

    const moveBackwardFn = (_) => {
        if (idx === 0) {
            return idx;
        }
        setIdx(idx - 1);
        setCurrentElement(dataRef.current[idx - 1]);
        return idx - 1;
    };

    function calcSummary() {
        const newSummary = dataRef.current.reduce((acc, element) => {
            if (element.known === "UNDEFINED") {
                return {...acc, undefined: acc.undefined + 1};
            }
            if (element.known === "UNKNOWN") {
                return {...acc, unknown: acc.unknown + 1};
            }
            if (element.known === "KNOWN") {
                return {...acc, known: acc.known + 1};
            }
            if (element.known === "LEARNED") {
                return {...acc, learned: acc.learned + 1};
            }
            return acc;
        }, {unknown: 0, known: 0, learned: 0, undefined: 0});
        console.log('summary', newSummary);
        setSummary({...newSummary});
    }

    return {
        currentElement,
        setCurrentElement,
        dataLength,
        idx,
        onChange,
        insertFn,
        deleteFn,
        moveForwardFn,
        moveBackwardFn,
        summary,
        reloadFn: fetchData,
    };
}

export default useCursor;
