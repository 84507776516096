import React from "react";
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import ErrorPage from "./components/ErrorPage";
import {GlobalErrorStateProvider} from "./context/ErrorContext";
import ErrorBoundary from "./components/ErrorBoundary";
import EditFlashCardSet from "./components/EditFlashCardSet";
import {GlobalUserStateProvider} from "./context/UserStateContext";
import EditFlashCardSetWizard from "./components/EditFlashCardSetWizard";
import AddFlashCardSet from "./components/AddFlashCardSet";
import CheckFlashCards from "./components/CheckFlashCards";
import theme from "./theme";
import {ThemeProvider} from "@mui/material/styles";

function App() {
    console.log('App.js');
    return (
        <ThemeProvider theme={theme}>
            <GlobalUserStateProvider>
                <GlobalErrorStateProvider>
                    <ErrorBoundary>
                        <BrowserRouter
                            future={{
                                v7_relativeSplatPath: true,
                                v7_startTransition: true,
                            }}
                        >
                            <Routes>
                                <Route path="/dashboard" component={<Dashboard/>}/>
                                <Route path="/error" element={<ErrorPage/>}/>
                                <Route path={`/flash-card-sets/new`} element={<AddFlashCardSet/>}/>
                                <Route path={`/flash-card-sets/:id`} element={<EditFlashCardSet/>}/>
                                <Route path={`/flash-card-sets/:id/check`} element={<CheckFlashCards/>}/>
                                <Route path={`/flash-card-sets/:id/wizard`} element={<EditFlashCardSetWizard/>}/>
                                <Route path="*" element={<Dashboard/>}/>
                            </Routes>
                        </BrowserRouter>
                    </ErrorBoundary>
                </GlobalErrorStateProvider>
            </GlobalUserStateProvider>
        </ThemeProvider>
    );
}

export default App;
