import React, {useContext} from "react";
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import {UserStateContext} from "../context/UserStateContext";
import MenuIcon from '@mui/icons-material/Menu';
import UserInfo from "./UserInfo";
import {useNavigate} from "react-router-dom";

function Header() {
    const navigate = useNavigate();
    let {state: userState} = useContext(UserStateContext);
    return (
        <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar onClick={()=> navigate('/')}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{mr: 2}}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    Flash Cards
                </Typography>
                {userState && (
                    <UserInfo/>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
